
.title {
    color: #00649B; 
    font-size:18px;  
    font-weight: 300; 
}
.text-red {
    color: red;
    margin-left: 2.2em;
}
