/* Tables */

/*Header*/
.table thead {
    background-color: #F6F6F6;
    

}

    .table thead tr {
        border: 1px solid white;
        
    }

    .table thead th {
        border: 1px solid white;
        text-align: center;
        font-size: 16px;
        color: #333;
        font-weight:normal;
    }

.table thead {
    border-bottom: 0px;
}

    .table thead tr {
        border-bottom: 0px;
    } 
        .table thead tr th {
            border-bottom: 0px;
        }

/*Body */
.table tr td{
    border: 1px solid #D9D9D9; 
}
.table tbody { 

}

    .table tbody tr {
        color: #00649B;
    }
    .table tbody tr td{
        font-size: 16px;
        font-weight:normal;
    }

    .table tbody td:nth-of-type(1n+1) {
        text-align: center;
    }

    .table tbody td:first-of-type {
        text-align: left;
    }

    .table tbody tr td {
        border: 1px solid white;
    }

    .table tbody tr:nth-child(odd) {
        background-color: white;
    }

    .table tbody tr:nth-child(even) {
        background-color: #f6f6f6;
    }

/*Footer */
.table tfoot {
    background: #00415E;
    color: #FFF;
}
.table tfoot tr td{
    font-size: 16px;
}
.table tfoot td:nth-of-type(1n+1) {
    text-align: center;
}

.table tfoot td:first-of-type {
    text-align: left;
}