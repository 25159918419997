
.header-card{
    min-height:50px;
    color:white;
}


.header-value{
    font-size:48px;
    font-weight:300;
}

.header-title {
    font-style: italic;
    font-size:16px;
    font-weight: 300;
}