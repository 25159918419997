
* {
    font-family: "Open Sans", sans-serif; 
}

.table-graphs a {
    color:#FFF;
    text-decoration:none;
}

.table-graphs a:hover {
    color: #FFF;
}

.table-graphs tbody{
    border-top:0px !important; 
}

.table-graphs tbody tr:last-child td{
    border-bottom:0px;
}

.table-graphs tfoot  {
    border-top: 0px !important;
}
.table-graphs .container-fluid:first-of-type .table tbody tr:nth-child(1) {
    background-color: #00649b;
    color: #FFF;
    cursor:pointer;
}

.table-graphs .container-fluid:first-of-type .table tbody tr:nth-child(2) {
    background-color: #4CB433;
    color: #FFF;
    cursor: pointer;
}

.table-graphs .container-fluid:first-of-type .table tbody tr:nth-child(3) {
    background-color: #4CB433;
    color: #FFF;
    cursor: pointer;
}

.table-graphs .container-fluid:first-of-type .table tbody tr:nth-child(4) {
    background-color: #4CB433;
    color: #FFF;
    cursor: pointer;
}
 

.table-graphs .container-fluid:first-child {
    background-color: #E8F7FF !important;
}

.table-graphs .container-fluid:nth-child(odd) {
    background-color: #EEE;
}

h1 {
    font-size: 24px; 
}

h2 {
    font-size: 22px; 
}

p {
    font-size: 18px;
    font-weight: normal;
}

label {
    font-size: 16px; 
}

span {
    font-size:14px; 
}



.bg-matterhorn {
    background: #535353
}

.bg-prussian-blue {
    background: #00415E
}

.bg-american-green {
    background: #4CB433
}
 
.bg-cerulean {
    background-color: #00649b;
}

.bg-whisper {
    background-color: #EEE;
}
 
   

.bg-prussian-blue {
    background: #00415e;
}
  
.text-cerulean {
    color: #00649b;
}

.text-skyblue {
    color: #89b2cd;
}

.text-prussian-blue {
    color: #00415e;
}

.btn-gray {
   background-color: #CED4DA !important;

}
